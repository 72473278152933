<template>
  <div class="box">
    <!--  搜索  -->
    <div class="search-box flex_row m_b_10">
      <el-input class="m_r_30" v-model="params.name" placeholder="搜索直播、课程"></el-input>
      <el-button type="primary" @click="getCourseList()">搜索</el-button>
    </div>
    <div class="m_tb_20 pointer" style="margin-top: 50px" v-loading.fullscreen.lock="loading">
      <courseList :courseListData="courseData"/>
      <el-empty description="暂无数据" v-show="!courseData.length"></el-empty>
    </div>
    <!--  分页  -->
    <div class="pagination">
      <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="12"
          v-on:current-change="currentChange_click"
          hide-on-single-page
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import CourseList from "@/components/search/courseList";
import {getCourseList} from '@/api/index'

export default {
  name: 'searchResult',
  components: {
    CourseList
  },
  data: () => {
    return {
      // 请求参数
      params: {
        current: 1,
        size: 12,
        isTelecast: '3',
        name: ''
      },
      keyword: '',
      type: '',
      courseData: [],
      // 数据总条数
      total: 0,
      // 加载
      loading: false
    }
  },
  mounted() {
    this.getCourseList()
  },
  methods: {
    getCourseList() {
      this.loading = true
      getCourseList(this.params).then(res => {
        this.loading = false
        this.total = res.data.telecastCourse.total
        this.courseData = this.processingData(res.data.telecastCourse.records)
      })
    },
    // 分页
    currentChange_click(current) {
      console.log('当前第', current, '页')
      this.params.current = current
      this.getCourseList()
    },
    processingData(data) {
      if (!data) {
        return
      }
      data.forEach((item) => {
        switch (item.state) {
          case '0':
            item.stateVal = 4
            break
          case '1':
            switch (item.beginState) {
              case '0':
                item.stateVal = 2
                break
              case '1':
                item.stateVal = 3
                break
              case '2':
                item.stateVal = 1
                break
            }
            break
          case '2':
            item.stateVal = 0
            break
          case '3':
            item.stateVal = 5
            break
        }
        item.courseType = 'live'
      })

      return data
    }
  }
}

</script>
<style scoped>
.box {
  width: 120rem;
  margin: 1rem auto;
}

.search-box {
  width: 700px;
  margin: 0 auto;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px
}

</style>
