<template>
  <div class="box m_tb_20">
    <el-row class="flex_row_wrap list">
      <div class="course-box bg_fff br_10 of_h m_b_20" v-for="(item,index) of courseListData" :key="index">
        <div class="flex_col pointer" @click="drump(item)" style="position: relative;">
          <div style="height: 180px;overflow: hidden;">
            <img :src="imgToRespomse(item.coverUrl)"
                 style="object-fit: cover;"
                 width="100%"
                 height="100%"
                 alt="课程"/>
          </div>
          <div class="p_in_10" style="position: relative;">
            <div class="fs_14  m_b_10" v-if="item.courseType === 'live'">
              {{ item.courseStartTime }}
            </div>
            <div class="fs_16 m_b_10 text_hidden" style="height:22px;width:230px;">
              {{ item.name }}
            </div>
            <div class="fs_14 c_666 flex_row" style="line-height: 20px;padding-top:10px;">
              <span>{{ item.teacherName ? item.teacherName : item.remarks1 }}</span>
              <span class="live_tag flex_row">
                <img class="m_lr_10" width="20px" :src="playImg" alt="播放量">
                <span>
                  {{ item.browseNumber > 10000 ? (item.browseNumber / 10000).toFixed(1) + ' 万' : item.browseNumber }}
                </span>
              </span>
            </div>

            <!--            <el-tag size="small" :type="stateColorJSON[item.stateVal]" class="live_tag" v-if="item.stateVal">
                          {{ stateJSON[item.stateVal] }}
                        </el-tag>-->
          </div>
          <div class="tag c_fff" :class="{'living':item.beginState == 0 }" v-show="item.beginState">
            {{ item.beginState == 0 ? '直播中' : '未开始' }}
          </div>
        </div>
      </div>
    </el-row>
  </div>
</template>
<script>
import defaultImg from '@/assets/image/default.png'
import {sessionGet} from "@/utils/local";
import playImg from '@/assets/image/play.png'
import {imgToRespomse} from '@/utils/imgToResponse'
import {sessionSet} from "../../utils/local";

export default {
  name: 'CourseList',
  props: {
    courseListData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: () => {
    return {
      defaultImg,
      playImg,
      /*
      * stateVal
      *   0 未通过
      *   1 未开始
      *   2 直播中
      *   3 已结束
      *   4 审核中
      * */
      stateJSON: {
        0: '未通过',
        1: '未开始',
        2: '直播中',
        3: '已结束',
        4: '审核中',
        5: '待审核'
      },
      stateColorJSON: {
        0: 'warning',
        1: 'info',
        2: 'success',
        3: 'danger',
        4: '',
        5: ''
      },
    }
  },
  methods: {
    imgToRespomse(url) {
      return imgToRespomse(url)
    },
    drump(item) {
      sessionSet('details', item)
      let userInfo = sessionGet('userInfo')

      if (this.$route.name === 'liveSearch') {
        if (!userInfo) {
          this.$router.push('/login')
        } else {
          // 不需要报名：1需要报名 0不需要
          if (item.isSubscribe == 0) {
            // 直播中
            if (item.beginState == 0) {
              // 直播：1直播 0录播
              if (item.isLubo == 1) {
                // 是否是自己的直播课
                if (item.updateUserId === userInfo.user_id) {
                  this.$router.push('/order?courseId=' + item.id)
                } else {
                  this.$router.push('/liveStu/player?telecastId=' + item.id)
                }
                // 录播
              } else {
                this.$router.push('/liveStu/playerLubo?telecastId=' + item.id)
              }
              // 未开播
            } else {
              this.$router.push('/order?courseId=' + item.id)
            }
            // 需要报名
          } else {
            // 直播中
            if (item.beginState == 0) {
              // 是否已报名：>0已报名 <=0未报名
              if (item.subscribeId > 0) {
                // 直播
                if (item.isLubo == 1) {
                  this.$router.push('/liveStu/player?telecastId=' + item.id)
                  // 录播
                } else {
                  this.$router.push('/liveStu/playerLubo?telecastId=' + item.id)
                }
                // 未报名
              } else {
                this.$router.push('/order?courseId=' + item.id)
              }
            } else {
              this.$router.push('/order?courseId=' + item.id)
            }
          }
        }
      } else {
        this.$router.push({path: '/courseDetail', query: {'courseId': item.id}})
      }
    }
  }
}
</script>
<style scoped>
.box {
  width: 1200px;
}

.title-box {
  justify-content: space-between;
}

.course-box {
  /*width      : 57.5rem;*/
  width: 285px;
  transition: all .5s;
  margin-right: 20px;
}

/*
.list > div:nth-child(odd) {
  margin-right : 50px;
}*/

list > div {
  margin-right: 20px;
}

.list > div:nth-child(4n+0) {
  margin-right: 0;
}


.course-box img {
  transition: all .5s;
}

.course-box:hover {
  box-shadow: 0 0 10px 2px #e0e0e0;
  transition: all .5s;
}

.course-box:hover img {
  transform: scale(1.1);
  transition: all .5s;
}

.live_tag {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.tag {
  position: absolute;
  padding: 4px 10px;
  background-color: #4390f6;
  top: 0;
  right: 0;
  border-radius: 0 10px 0 10px;
}

.living {
  background-color: #eb4251;
}
</style>
